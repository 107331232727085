/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React, { useState,useRef,useEffect } from 'react';
import withWrapper from '../components/wrapper.js';
import { navigate } from "gatsby";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from '../aws-exports';
import { useAppContext } from "../libs/contextLib";
import { Helmet } from "react-helmet";
import {checkCreateCompany} from "../libs/loginLib";

Amplify.configure(awsconfig);

function _inner (props) {
  const [isAuthenticated, userHasAuthenticated] = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmed, setConfirmed] = useState("");
  function parseQuery(queryString) {
      var query = {};
      var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
      for (var i = 0; i < pairs.length; i++) {
          var pair = pairs[i].split('=');
          query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
      return query;
  }
  useEffect(() => {
    let qu = parseQuery(props.location.search);
    if(qu.confirmed=="true")
      setConfirmed(true);
    console.log("isAuthenticated!!!");
    console.log(isAuthenticated);
  },[props.location]);

  function validateForm() {
    let val=email.length>3 && password.length > 7;
    return val;
  }
  
  function setIsLoading() {
      
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      
      var res=await Auth.signIn(email, password);
      var k=res.signInUserSession.idToken.jwtToken;
      //getIdentityId()
      userHasAuthenticated(true);
      window.localStorage.setItem("email", JSON.stringify(email));
      checkCreateCompany(k,function() {
        if(confirmed) {
          navigate("/?newCompany");
        } else 
          navigate("/");
      });  //callback is null


    } catch (e) {
      props.setText("Failure: "+e.message);
      setIsLoading(false);
    }
  }
  
  let topstyle={position: 'relative',
    width: '100%',
  };
  
  let innerStyle={
    
  };
  
  return (
    <div className="LoginForm" style={topstyle} >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign In</title>
        <link rel="canonical" href="https://www.valtrace.com/signin/" />
        <html lang="en" />
      </Helmet>

      <div style={innerStyle} >
        <style>{`
          .LoginForm input {
            border-radius: 2px;
            border: 1px solid #aaa;
            padding: 2px;
          }
        `}
        </style>
        {confirmed ? 
          <div style={{textAlign:'center',padding:'10px'}}>Thank you for confirming your email address.</div>
          :
          <span></span>
        }
          <form onSubmit={handleSubmit}>
            <div style={{padding:'5px 0px', textAlign:'center'}} className="form-group">
                <input placeholder="Email" autoFocus style={{marginLeft:'15px',width:'15em'}} type="email" className="form-control Hip" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div style={{position:'relative',padding:'5px 0px', textAlign:'center'}} className="form-group">
                <div style={{display:'inline-block',position:'relative'}}>
                  <input type="password" placeholder="Password" style={{marginLeft:'15px',width:'15em'}} className="form-control Hip" value={password} onChange={e => setPassword(e.target.value)} />
                </div>
            </div>
            <div style={{clear:'both',padding:'5px 0px',width:'100%', textAlign:'center'}}>
              <button type="submit" className='btn' disabled={!validateForm()} >Log In</button>
            </div>
          </form>
        </div>
    </div>
  );
}

export default withWrapper(_inner);